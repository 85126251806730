@font-face {
    font-family: 'Lato';
    src: url('../public/fonts/Lato/Lato-Regular.ttf');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    background: rgb(55,53,87);
    background: linear-gradient(81deg, rgba(55,53,87,1) 0%, rgba(16,16,41,1) 100%);
    color: #fae7e6;
    font-size: 20px;
}

img {
    max-width: 100%;
}

button {
    cursor: pointer;
}

a {
    color: inherit;
    text-decoration: none;
}

ul li {
    list-style: none;
}

.App {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

#home {
    flex: 1;
}