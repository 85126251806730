.header {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 25px;
    font-weight: 700;
}

.header-container {
    display: flex;
    align-items: center;
}

.header__coin {
    margin-left: 10px;
    width: 26px;
}