.referral {
    max-width: 90%;
    margin: auto;
}

.referral-list__container {
    margin-top: 45px;
}

.referral-list__title {
    text-align: center;
    margin-bottom: 30px;
}

.referral-list {
    background: rgba(43, 43, 74, 0.8);
    box-shadow: 0 1px 9px 5px rgba(30, 29, 29, 0.53);
    border-radius: .5rem;
    height: 260px;
    padding: 10px;
    font-size: 18px;
    overflow-y: auto;
    line-height: 30px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #cecece;
    align-items: center;
}

.referral-list ul li {
    list-style: inside;
}

.referral-info {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    align-items: center;
}

.referral-img {
    width: 15px;
    margin-left: 5px;
}

.referral-adress {
    margin-top: 5px;
    font-size: 15px;
    text-align: center;
}

.referral-adress span {
    text-decoration: underline;
    cursor: pointer;
}
