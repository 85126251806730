.boost {
    max-width: 90%;
    margin: auto;
    font-size: 16px;
}

.boost-title {
    text-align: center;
    margin: 45px 0 30px;
    font-size: 24px;
}

.boost-icon {
    height: 40px;
}

.boost__tap-wrapper {
    margin-bottom: 20px;
}

.boost__block-wrapper {
    cursor: pointer;
    display: flex;
    gap: 30px;
    margin-bottom: 10px;
    align-items: center;
    border: 1px solid gray;
    border-radius: 12px;
    padding: 17px 25px;
    width: 100%;
    background-color: transparent;
    color: inherit;
    font-size: 16px;
    text-align: left;
}

.boost__block-text span{
    color: gray;
}

.boost-block-upgrade {
    margin: 5px 0;
}

.popup {
    position: fixed;
    z-index: 999;
    text-align: center;
    left: 50%;
    width: 100vw;
    height: 50vh;
    border-radius: 13px 13px 0 0;
    bottom: -50vh;
    transform: translateX(-50%);
    padding: 2em;
    background-color: #282c34;
    box-shadow: 17px 0 20px 15px #00000088;
    animation: slideIn .3s forwards;
}

@keyframes slideIn {
    0% {
        bottom: -50vh;
    }
    100% {
        bottom: 0;
    }
}

.close {
    position: absolute;
    top: 10px;
    right: 20px;
}

.popup-content div {
    margin-top: 7px;
}

.popup-content-wrapper h3{
    margin-bottom: 30px;
}

.popup-content-wrapper div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
}

.popup-content-wrapper span {
    display: block;
    font-size: 30px;
}

.modal-btn {
    background-color: #4682fa;
    width: 90%;
    margin: auto;
    padding: 10px 90px;
    border-radius: 6px;
    color: #fff;
    font-size: 16px;
    outline: none;
    border: none;
}

.popup-coin {
    width: 30px;
    margin-left: 5px;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.boost-modal {
    font-size: 18px;
    text-align: center;
}
