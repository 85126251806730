.coin-wrapper {
    margin: 50px auto 0;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.coin-img {
    cursor: pointer;
    margin: auto;
    width: 350px;
}